const CurrentSubStepTitle = (salesOrderStatus:number) => {
    if (salesOrderStatus === 1) {
        return 'Creato';
    } else if (salesOrderStatus === 2) {
        return 'Confermato';
    } else if (salesOrderStatus === 3) {
        return 'Pagato';
    } else if (salesOrderStatus === 4) {
        return 'Completo';
    } else if (salesOrderStatus === 5) {
        return 'In Preparazione';
    } else if (salesOrderStatus === 6) {
        return 'Fatturato';
    } else if (salesOrderStatus === 7) {
        return 'Immatricolato';
    } else if (salesOrderStatus === 8) {
        return 'Spedito';
    } else if (salesOrderStatus === 9) {
        return 'Consegnato';
    } else if (salesOrderStatus === 10) {
        return 'Reso richiesto';
    } else if (salesOrderStatus === 11) {
        return 'Reso in elaborazione';
    } else if (salesOrderStatus === 12) {
        return 'Reso completato';
    } else {
        return '';
    }
};

export default CurrentSubStepTitle;