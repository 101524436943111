/* tslint:disable: no-duplicate-imports */
import { IGroup, IGroupDelivery, IGroups, IHeader, IList, IOrderHistoryViewProps, IOrderInformation, ISalesOrder } from '@msdyn365-commerce-modules/order-management';
import { Module, Node } from '@msdyn365-commerce-modules/utilities';
import {getSalesOrderDetailsBySalesIdAsync} from '@msdyn365-commerce/retail-proxy/dist/DataActions/SalesOrdersDataActions.g';
import * as React from 'react';
import currentSubStepTitle from '../utils/currentSubStepTitle';
interface IOrderInformationC extends IOrderInformation {
    salesStatus: string | undefined;
}
export const OrderHistoryOrderInfomation: React.FC<IOrderInformationC> = ({
    orderInformationProps,
    salesId,
    receiptId,
    channelName,
    createdDate,
    count,
    amount,
    channelReferenceId,
                                                                              salesStatus
}) => {
    // @ts-ignore
    const salesIdValue = salesId && salesId.props.children.split(': ')[1];

    return (
        <Node {...orderInformationProps}>
            {channelName}
            {receiptId}
            {salesIdValue && (
                <div><span className='ms-order-history__order-information-label'>N. ordine:</span><span><strong>{' '}{salesIdValue}</strong></span></div>
            )}
            <div><span className='ms-order-history__order-information-label'>Ordine del:</span>{' '}{createdDate}</div>
            <div><span className='ms-order-history__order-information-label'>N° articoli:</span>{' '}{count}</div>
            {salesStatus !== 'canceled' &&
                <div><span className='ms-order-history__order-information-label'>Prezzo:</span>{' '}{amount}</div>
            }

            {channelReferenceId}
        </Node>
    );
}

export const OrderHistoryGroupDelivery: React.FC<IGroupDelivery> = ({ deliveryProps, heading, count, processing, address, trackingInfo }) => (
    <Node {...deliveryProps}>
        {heading}
        {count}
        {processing}
        {address}
        {trackingInfo}
    </Node>
);

export const OrderHistoryGroup: React.FC<IGroup> = ({ groupProps, salesLinesProps, salesLines }) => {
    return(
        <Node {...groupProps}>
            {salesLines && (
                <Node {...salesLinesProps}>
                    {salesLines.map(salesLine => (
                        <>
                            <div
                                className='ms-order-history__sales-line-product'
                                key={salesLine.data.salesLine.LineId}
                            >
                                {salesLine?.data?.product?.Name}
                            </div>
                            <React.Fragment key={salesLine.data.salesLine.LineId}>
                                {salesLine.salesLine}
                            </React.Fragment>
                        </>
                    ))}
                </Node>
            )}
        </Node>
    );
};

export const OrderHistoryGroups: React.FC<IGroups> = ({ groupsProps, groups }) => (
    <Node {...groupsProps}>
        {groups.map((group, index) => (
            <OrderHistoryGroup key={index} {...group} />
        ))}
    </Node>
);

export const OrderHistoryHeader: React.FC<IHeader> = ({ headerProps, heading, orderCountLabel, extraActions }) => (
    <Node {...headerProps}>
        {heading}
        {orderCountLabel}
        {extraActions}
    </Node>
);

export const OrderHistorySalesOder: React.FC<ISalesOrder> = (props) => {
    const { salesOrderProps, orderInfomation, groups, orderDetailsLink } = props;
    const salesStatus = props?.groups?.groups && props.groups.groups[0].data.salesStatus;
    // @ts-ignore
    const orderDetailsHref = orderDetailsLink && orderDetailsLink.props?.href;
    const salesId = orderDetailsHref.split('salesId=').pop();
    // @ts-ignore
    const context = props.actionContext;
    const[salesOrderStatus, setSalesOrderStatus] = React.useState(1);
    // as to bug in default link to order detail page
    // const orderDetailsLinkCustom = React.cloneElement(orderDetailsLink as React.ReactElement<any>,{ href: `/orderdetails?salesId=${salesId}` });
    React.useEffect(() => {
        getSalesOrderDetailsBySalesIdAsync({ callerContext: context }, salesId).then((result) => {
            const orderStatus = result?.ExtensionProperties?.filter(i => i.Key === 'SalesOrderStatus_CAP')[0].Value?.StringValue;
            orderStatus && setSalesOrderStatus(Number(orderStatus));
        });
    });

    const statusColor = (() => {
        if (salesOrderStatus === 9 || salesOrderStatus === 12) {
            return 'done';
        } else {
            return 'in-progress';
        }
    })();
    // @ts-ignore

    return (
        <Node {...salesOrderProps}>
            {orderInfomation && <OrderHistoryOrderInfomation {...orderInfomation} salesStatus={salesStatus}/>}
            {groups && <OrderHistoryGroups {...groups} />}
            <div className={'ms-order-history__status'}>
                <h5 className={'ms-order-history__status__heading'}>Stato dell'ordine</h5>
                <div className={`ms-order-history__status__name ms-order-history__status__name--${statusColor}`}>{currentSubStepTitle(salesOrderStatus)}</div>
                {orderDetailsLink}
            </div>

        </Node>
    );
}

export const OrderHistoryList: React.FC<IList> = ({ listProps, salesOrders, ...other }) => {
    return (
        <Node {...listProps}>{salesOrders && salesOrders.map((salesOrder, index) => <OrderHistorySalesOder key={index} {...salesOrder} {...other} />)}</Node>
    );
}

const OrderHistoryView: React.FC<IOrderHistoryViewProps> = ({
    context,
    orderHistoryProps,
    header,
    alert,
    loading,
    emptyMessage,
    backToShoppingLink,
    list,
    moreButton
}) => {
    return (
        <Module {...orderHistoryProps}>
            {header && <OrderHistoryHeader {...header} />}
            {loading}
            {alert && (
                <>
                    {alert}
                    {backToShoppingLink}
                </>
            )}
            {emptyMessage && (
                <>
                    {emptyMessage}
                    {backToShoppingLink}
                </>
            )}
            {list && <OrderHistoryList {...list} {...context}/>}
            {moreButton && moreButton}
        </Module>
    );
}

export default OrderHistoryView;
